import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { CartContext } from "../App";
import "./BottomTabBar.css";

const tabBarItems = [
  {
    id: 1,
    activeIcon: "./images/homeActive.png",
    icon: "./images/homeInactive.png",
    route: "/",
  },
  {
    id: 2,
    activeIcon: "./images/cartActive.png",
    icon: "./images/cartInactive.png",
    route: "/checkout",
  },
  {
    id: 3,
    activeIcon: "./images/profileActive.png",
    icon: "./images/profileInactive.png",
    route: "/profile",
  },
];

const BottomNavigation = ({ items = tabBarItems, selected = 0 }) => {
  const [current, setCurrent] = useState(selected);
  const location = useLocation();
  const { cartList } = useContext(CartContext);

  useEffect(() => {
    if (location.pathname.includes("checkout")) {
      setCurrent(1);
    } else if (location.pathname.includes("profile")) {
      setCurrent(2);
    } else {
      setCurrent(0);
    }
  }, [location.pathname]);

  const navItems = items.map((item, idx) => {
    return (
      <Link to={item.route} key={idx} style={{ flex: 1 }}>
        <div className="bottom_nav_item">
          {item.id === 2 && cartList.length > 0 && (
            <div className="cart_length_container">
              <p className="cart_length_text">{cartList.length}</p>
            </div>
          )}
          {current === idx ? (
            <img
              src={item.activeIcon}
              alt="active icon"
              height={24}
              width={24}
            />
          ) : (
            <img src={item.icon} alt="inactive icon" height={24} width={24} />
          )}
        </div>
      </Link>
    );
  });
  return <div className="bottom_nav">{navItems}</div>;
};

export default BottomNavigation;
