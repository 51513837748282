import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import suprsend from "@suprsend/web-sdk";

export default function Login() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [subsId, setSubsId] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [tabs, setTabs] = useState("");
  const [tenant, setTenant] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (email && password) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          name: "mike",
          email: email,
          password: String(password),
          gender: gender,
          tenant: tenant,
          tabs: tabs,
        })
      );

      if (subsId) {
        localStorage.setItem("subscriber_id", subsId);
      }
      suprsend.identify(email);
      suprsend.set_super_properties({ gender: gender });
      suprsend.user.set_once("first_login_at", new Date());
      suprsend.user.set_once({
        name: "mike",
        email: email,
        password: String(password),
        gender: "Male",
      });
      suprsend.user.add_email(email);
      suprsend.user.set("login_at", new Date());
      suprsend.track("$user_login", { email });
      suprsend.user.increment("login_count", 1);
      history.push("/");
    }
  }

  return (
    <div className="Login" style={{ margin: 32 }}>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Form.Group size="lg" controlId="email" style={{ marginBottom: 24 }}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            autoComplete="false"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="subsId" style={{ marginBottom: 24 }}>
          <Form.Label>Subscriber id</Form.Label>
          <Form.Control
            autoComplete="false"
            type="text"
            value={subsId}
            onChange={(e) => setSubsId(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            autoComplete="false"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="subsId" style={{ marginBottom: 24 }}>
          <Form.Label>Tenant</Form.Label>
          <Form.Control
            autoComplete="false"
            type="text"
            value={tenant}
            onChange={(e) => setTenant(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="subsId" style={{ marginBottom: 24 }}>
          <Form.Label>Tabs stores</Form.Label>
          <Form.Control
            autoComplete="false"
            type="text"
            value={tabs}
            onChange={(e) => setTabs(e.target.value)}
          />
        </Form.Group>
        <select
          className="form-select"
          onChange={(e) => {
            setGender(e.target.value);
          }}
          value={gender}
        >
          <option value="">Open this select menu</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        <Button block size="lg" type="submit" disabled={!validateForm()}>
          Login
        </Button>
      </Form>
    </div>
  );
}
