export default function MiniProductCard({ item }) {
  return (
    <>
      <div style={styles.container}>
        <div>
          <p style={styles.itemTitle}>{item.title}</p>
          <p style={styles.category}>Special Design</p>
          <div style={styles.priceDiv}>
            <p style={styles.price}>${item.price}</p>
            <p style={styles.instock}>In stock</p>
          </div>
        </div>
        <div>
          <img src={item.image} height={50} width={50} alt="product" />
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 24px",
    borderBottom: "1px solid #ebebeb"
  },
  itemTitle: {
    marginRight: 6,
    fontSize: 14,
    lineHeight: 1.2
  },
  category: {
    fontSize: 12,
    color: "gray"
  },
  priceDiv: {
    display: "flex",
    alignItems: "center"
  },
  price: {
    fontSize: 14,
    color: "dimgray",
    fontWeight: 600
  },
  instock: {
    color: "green",
    fontSize: 14,
    marginLeft: 12
  }
};
