import { useContext, useEffect } from "react";
import suprsend from "@suprsend/web-sdk";
import { CartContext } from "../App";
import MiniProductCard from "../components/MiniProductCard";
import PaymentDetails from "../components/PaymentDetails";
import itemsData from "./data.json";
import { useHistory } from "react-router-dom";
import "./Checkout.css";

function Checkout() {
  const history = useHistory();
  const { cartList } = useContext(CartContext);
  const totalItems = cartList.length;

  useEffect(() => {
    suprsend.track("Page Load", { page: "Checkout Page" });
  }, []);

  const cartItems = itemsData.filter((item) => {
    const res = cartList.includes(item.id) ? item : null;
    return res;
  });

  const cartTotalAmount = cartItems.reduce(
    (amount, item) => amount + item.price,
    0
  );

  return (
    <>
      <div className="container_checkout">
        <div>
          <p className="checkout_help_text">Checked Out Products</p>
          <p className="total_text">Total items: {totalItems}</p>
        </div>
      </div>
      {totalItems ? (
        cartItems.map((item) => <MiniProductCard item={item} key={item.id} />)
      ) : (
        <p className="empty_text">Your cart is empty</p>
      )}
      <PaymentDetails />
      {!!cartTotalAmount && (
        <div className="order_summary_container">
          <div className="order_summary_tile">
            <p className="order_summary_text">Order Amount</p>
            <p className="order_summary_text">{cartTotalAmount}$</p>
          </div>
          <div className="order_summary_tile">
            <p className="order_summary_text">Delivery Charges</p>
            <p className="order_summary_text">12$</p>
          </div>
          <div className="order_summary_tile">
            <p className="order_summary_total">Total to be paid</p>
            <p className="order_summary_total">{cartTotalAmount + 12}$</p>
          </div>
        </div>
      )}
      <div
        className="pay_now_button"
        onClick={async () => {
          let productNames = "";
          let productID = "";
          let productAmount = "";
          cartItems.forEach((element) => {
            productNames += `${element.title},`;
            productID += `${element.id},`;
            productAmount += `${element.price},`;
          });
          suprsend.track("$purchase_made", {
            product_id: productID,
            product_name: productNames,
            amount: productAmount,
          });
          suprsend.user.increment({
            order_count: cartItems.length,
            amount: cartTotalAmount + 12,
          });
          history.push("/success");
        }}
      >
        <p className="pay_now_button_text">Pay Now</p>
      </div>
    </>
  );
}

export default Checkout;
