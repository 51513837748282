import React, { useContext } from "react";
import suprsend from "@suprsend/web-sdk";
import { CartContext } from ".././App";
import mixpanel from "mixpanel-browser";

export default function ProductCard({ item }) {
  const { setCartList, cartList } = useContext(CartContext);
  const InCart = cartList.includes(item.id);
  return (
    <div
      style={styles.container}
      onClick={() => {
        suprsend.track("Product Viewed", {
          "Product ID": item.id,
          "Product Name": item.title,
          Amount: item.price,
        });
        mixpanel.track("Product Viewed", {
          "Product ID": item.id,
          "Product Name": item.title,
          Amount: item.price,
        });
      }}
    >
      <img
        src={item.image}
        alt={item.title}
        height={150}
        width="100%"
        style={styles.image}
      />
      <div style={styles.bottomContainer}>
        <div style={styles.innerBottom}>
          <div>
            <p style={styles.itemTitle}>{item.title}</p>
            <p style={styles.category}>clothing</p>
          </div>
          <p style={styles.priceText}>${item.price}</p>
        </div>
        <button
          style={styles.buttonCart}
          onClick={(e) => {
            e.stopPropagation();
            suprsend.user.append("wishlist", item.title);
          }}
        >
          <p style={styles.add}>Like</p>
        </button>
        <button
          style={styles.buttonCart}
          onClick={(e) => {
            e.stopPropagation();
            suprsend.user.remove("wishlist", item.title);
          }}
        >
          <p style={styles.add}>UnLike</p>
        </button>
        <button
          style={styles.buttonCart}
          onClick={(e) => {
            e.stopPropagation();
            if (InCart) {
              suprsend.track("Removed trom Cart", item);
              cartList.splice(cartList.indexOf(item.id), 1);
              setCartList([...cartList]);
            } else {
              suprsend.track("Added to Cart", item);
              setCartList([...cartList, item.id]);
            }
          }}
        >
          <img
            src="./images/cart.png"
            alt="cart"
            height={16}
            width={16}
            style={{ marginRight: 8 }}
          />
          {InCart ? (
            <p style={styles.added}>Added</p>
          ) : (
            <p style={styles.add}>Add</p>
          )}
        </button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: "44%",
    margin: 6,
    width: 200,
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1)",
  },
  image: {
    padding: 12,
    maxHeight: 200,
  },
  bottomContainer: {
    padding: "12px 8px",
  },
  innerBottom: {
    display: "flex",
    justifyContent: "space-between",
  },
  itemTitle: {
    marginRight: 6,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2,
    paddingBottom: 6,
  },
  category: {
    fontSize: 12,
    color: "gray",
  },
  priceText: {
    fontSize: 16,
    color: "dimgray",
    fontWeight: 600,
  },
  add: { fontWeight: 500 },
  added: { fontWeight: 500, color: "green" },
  buttonCart: {
    border: "none",
    borderRadius: 10,
    fontSize: 12,
    float: "right",
    marginBottom: 10,
    padding: "8px 10px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFEFEF",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1)",
  },
};
