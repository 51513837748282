import ProductCard from "../components/ProductCard";
import data from "./data.json";
import { Link, useLocation } from "react-router-dom";
import suprsend from "@suprsend/web-sdk";
import "./Home.css";
import React, { useState } from "react";
import SuprsendInbox from "@suprsend/react-inbox";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapping = {
  "skme902902@gmail.com": "2t8r9WtUZhK8G97Kb0Bb96rr1PkJdmZQyrnLnfcVE4w",
  "anjali@suprsend.com": "ViNgogaGj18xy3hsLefFHhZoaDDsE7qbi6WzaLHHQ6E",
  "nikita@suprsend.com": "itg9xJEPLeIkU7_Ydq350KoD47S3rLU-BtPWsITlV7M",
  "karthick.k@suprsend.com": "xe8t1m7Nnp27OYa1OU5_dbUW3eYPu9uK4irXOb26XiU",
};

function Home() {
  const [id, setId] = useState("");
  const [themeType, setThemeType] = useState();
  const localtion = useLocation().search;
  const user = JSON.parse(localStorage.getItem("user"));
  const subsId = localStorage.getItem("subscriber_id");
  let query = useQuery();
  React.useEffect(() => {
    suprsend.web_push.register_push();
    const themeType = localStorage.getItem("theme");
    if (themeType) {
      setThemeType(themeType);
    } else {
      setThemeType("light");
    }
    suprsend.track("Page Load", {
      page: "Home Page",
      userAgent: navigator.userAgent,
    });
    let utm_obj = {
      utm_source: query.get("utm_source"),
      utm_medium: query.get("utm_medium"),
      utm_campaign: query.get("utm_campaign"),
      utm_id: query.get("utm_id"),
      utm_term: query.get("utm_term"),
      utm_content: query.get("utm_content"),
    };
    if (localtion) {
      suprsend.track("UTM Visited", utm_obj);
    }
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      const value = mapping[user.email];
      setId(value);
    }, 1000);
  }, [id]);

  const tenant = user.tenant;
  let stores = user.tabs;
  if (stores) {
    stores = JSON.parse(stores);
  }
  console.log("inbox data", tenant, stores);
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: themeType === "dark" ? "black" : "white" }}
      >
        <div>
          <p className="featured_text">Featured</p>
          <p className="items_count">20 items listed</p>
        </div>
        <div className="flex_container">
          {themeType && (
            <SuprsendInbox
              workspaceKey={process.env.REACT_APP_SS_KEY}
              workspaceSecret={process.env.REACT_APP_SS_SECRET}
              distinctId={user.email}
              tenantId={tenant}
              stores={stores}
              toastProps={{ position: "top-center" }}
              subscriberId={subsId}
              themeType={themeType}
            />
          )}
          <div className="sort_container">
            <img src="./images/sort.png" alt="sort" height={18} width={18} />
            <p className="help_text">Sort</p>
          </div>
          <div
            className="filter_img"
            onClick={() => {
              if (themeType === "light") {
                localStorage.setItem("theme", "dark");
                setThemeType("dark");
              } else {
                localStorage.setItem("theme", "light");
                setThemeType("light");
              }
            }}
          >
            <img src="./images/filter.png" alt="sort" height={18} width={18} />
            <p className="help_text">Theme</p>
          </div>
        </div>
      </div>
      <div className="product_list">
        {data.map((item) => (
          <ProductCard item={item} key={item.id} />
        ))}
        <Link to="/checkout" className="link">
          <p className="buy_text">Buy Now</p>
        </Link>
      </div>
    </>
  );
}

export default Home;
