import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import Checkout from "./pages/Checkout";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Success from "./pages/Success";
import Prefernces from "./pages/Preferences";
import BottomTabBar from "./components/BottomTabBar";
import suprsend from "@suprsend/web-sdk";

export const CartContext = React.createContext([]);

suprsend.init(process.env.REACT_APP_SS_KEY, process.env.REACT_APP_SS_SECRET, {
  vapid_key: process.env.REACT_APP_VAPID,
  api_url: process.env.REACT_APP_API_URL,
});

function PrivateRoute(props) {
  if (localStorage.getItem("user")) {
    return props.children;
  } else {
    return <Redirect to="/login" />;
  }
}

function ProtectedRoute(props) {
  if (localStorage.getItem("user")) {
    return <Redirect to="/" />;
  } else {
    return props.children;
  }
}

function App() {
  const [cartList, setCartList] = useState([]);
  const isPreference = window.location.pathname.includes("preferences");

  return (
    <div className="App">
      <CartContext.Provider value={{ cartList, setCartList }}>
        <Router>
          {!isPreference && <BottomTabBar />}
          <Switch>
            <ProtectedRoute exact path="/login">
              <Login />
            </ProtectedRoute>
            <PrivateRoute exact path="/">
              <Home />
            </PrivateRoute>
            <PrivateRoute exact path="/preferences">
              <Prefernces />
            </PrivateRoute>
            <PrivateRoute path="/checkout">
              <Checkout />
            </PrivateRoute>
            <PrivateRoute path="/profile">
              <Profile />
            </PrivateRoute>
            <PrivateRoute path="/success">
              <Success />
            </PrivateRoute>
          </Switch>
          <Route />
        </Router>
      </CartContext.Provider>
    </div>
  );
}

export default App;
