import React from "react";
import { useHistory, Link } from "react-router-dom";
import suprsend from "@suprsend/web-sdk";

function Profile() {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <div className="container_checkout" style={{ alignItems: "center" }}>
        <p className="checkout_help_text">Profile</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 24,
          alignItems: "center",
        }}
      >
        <img
          src="./images/profileActive.png"
          alt="inactive icon"
          height={100}
          width={100}
          style={{ margin: 24 }}
        />
        <p className="checkout_help_text">Hello {user.name}</p>
        <p style={{ margin: 12 }}>Email: {user.email}</p>
        <button class="button">Settings</button>
        <button
          class="button btn-pref"
          onClick={() => {
            window.location.href = "/preferences";
          }}
        >
          Preferences
        </button>
      </div>

      <div
        className="pay_now_button"
        onClick={() => {
          localStorage.removeItem("user");
          localStorage.removeItem("subscriber_id");
          suprsend.track("$user_logout");
          suprsend.user.unset("wishlist");
          suprsend.reset();
          history.push("/login");
        }}
      >
        <p className="pay_now_button_text">Logout</p>
      </div>
    </>
  );
}

export default Profile;
