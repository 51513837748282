import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactModal from "react-modal";
import "./PaymentDetails.css";
import suprsend from "@suprsend/web-sdk";

const initialCardsData = [
  {
    number: "123123987775",
    name: "Mike Wazowski",
    month: 12,
    year: 32,
  },
  {
    number: "123123987774",
    name: "mike wazowski",
    month: 12,
    year: 32,
  },
  {
    number: "123123987773",
    name: "mike wazowski",
    month: 12,
    year: 32,
  },
];

function PaymentDetails() {
  const [cards, setCards] = useState(initialCardsData);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    month: "",
    year: "",
  });

  return (
    <>
      <div className="payment_container">
        <p className="payment_heading">Payment</p>
        <p
          className="add_new_card"
          onClick={() => {
            setShowModal(true);
          }}
        >
          + Add new card
        </p>
      </div>
      <Carousel
        autoPlay={false}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        infiniteLoop={false}
        autoFocus={false}
        interval={24 * 1000}
      >
        {cards.map((item) => (
          <div className="debit_card_container" key={item.number}>
            <div className="form_top_view">
              <p style={{ fontSize: 16, letterSpacing: 3 }}>
                **** **** {item.number.slice(-4)}
              </p>
              <img
                src="./images/visa.jpg"
                height={20}
                width={24}
                alt="visa"
                style={{ height: 20, width: 24 }}
              />
            </div>
            <div className="form_bottom_view">
              <div>
                <p style={{ fontSize: 10, color: "dimgrey" }}>
                  Card holder name
                </p>
                <p>{item.name}</p>
              </div>
              <div>
                <p style={{ fontSize: 10, color: "dimgrey" }}>Expiry</p>
                <p>
                  {item.month}/{item.year}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <ReactModal
        isOpen={showModal}
        onRequestClose={() => {
          setFormData({ name: "", number: "", month: "", year: "" });
          setShowModal(false);
        }}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: 10,
            padding: 30,
          },
          content: { position: "static", marginTop: "40%" },
        }}
      >
        <h5>Add Card Details</h5>
        <label for="accNumber" className="form_label">
          Card Number
        </label>
        <input
          type="text"
          onChange={(e) => setFormData({ ...formData, number: e.target.value })}
        />
        <label for="accNumber" className="form_label">
          Card Holder Name
        </label>
        <input
          type="text"
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <select
          id="exp_month"
          name="month"
          value={formData.month}
          onChange={(e) => setFormData({ ...formData, month: e.target.value })}
        >
          <option value="" disabled></option>
          <option value={1}>Jan</option>
          <option value={2}>Feb</option>
          <option value={3}>Mar</option>
        </select>
        <select
          id="exp_year"
          name="year"
          value={formData.year}
          onChange={(e) => setFormData({ ...formData, year: e.target.value })}
        >
          <option value="" disabled></option>
          <option value={22}>22</option>
          <option value={23}>23</option>
          <option value={24}>24</option>
        </select>
        <button
          className="add_cart_button_modal"
          onClick={() => {
            if (
              formData.number &&
              formData.name &&
              formData.month &&
              formData.year
            ) {
              suprsend.user.set({
                card_name: formData.name,
                card_number: formData.number,
                card_expiry_month: formData.month,
                card_expiry_year: formData.year,
              });
              setCards([formData, ...cards]);
              setFormData({ name: "", number: "", month: "", year: "" });
              setShowModal(false);
            }
          }}
        >
          <p style={{ fontWeight: 500 }}>Add Card</p>
        </button>
      </ReactModal>
    </>
  );
}

export default PaymentDetails;
